/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useRef, useState } from 'react';
import LoadContent from '../../../components/Load-content';
import { IoCloseOutline } from 'react-icons/io5';
import './styles.scss';
import SecretaryResourceManagementCostumer from '../../../class/secretary-resource-management/secretary-resource-management-costumer';
import { type SecretaryResourceManagementType } from '../../../class/types/secretary-resource-management/secretary-resource-management';

interface FormAddResourceManagementProps {
  display: boolean
  setDisplay: React.Dispatch<React.SetStateAction<boolean>>
  elements: SecretaryResourceManagementType[]
  setElements: React.Dispatch<React.SetStateAction<SecretaryResourceManagementType[]>>
  element: SecretaryResourceManagementType | undefined
}

export default function FormEditResourceManagement({display, setDisplay, elements, setElements, element}: FormAddResourceManagementProps) {
  const [isLoading, setIsLoading] = useState(false);

  const title = useRef<HTMLInputElement | null>(null);
  const date = useRef<HTMLInputElement | null>(null);

  const huddleEdit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!element || !element.id) {
      return;
    }

    const form = e.target as HTMLFormElement;

    const inputs = form.querySelectorAll('input');
    const secretaryResourceManagement = new SecretaryResourceManagementCostumer(inputs);

    setIsLoading(true);
    secretaryResourceManagement.put(element.id).then(response => {
      console.log(response);
      if (!response) {
        setIsLoading(false);
        return
      }

      const indexElement = elements.findIndex((value) => response.id === value.id);

      if (typeof indexElement !== 'number') {
        setIsLoading(false);
        return;
      }

      elements[indexElement] = response;

      setElements([...elements]);

      setIsLoading(false);
      setDisplay(false)
    }).catch(e => {
      //
    });
  }

  const huddleClickClose = (bol: boolean) => {
    setDisplay(bol);
  }

  useEffect(() => {
    if (!title.current || !date.current || !element) {
      return;
    }

    title.current.value = element?.title;
    date.current.value = element?.date;
  }, [display])

  return (
    <div className={`form-edit-resource-management display-${display}`}>
      <form method='POST' className='form-edit-element' id='form-edit-element' onSubmit={e => {
        huddleEdit(e);
      }}>
        <div className='header-form-edit-element-content'>
          <h1>
            Editar uma aba de gerenciamento de recurso
          </h1>
          <IoCloseOutline onClick={() => {
            huddleClickClose(false);
          }} size='25' />
        </div>
        <div className='body-form-edit-element-content'>
          <div className='input-content'>
            <label htmlFor='edit-resource-management-title'>Titulo:</label>
            <input type='text' id='resource-management-title' placeholder='Digite um titulo para o seu gerenciador de recursos' ref={title} />
          </div>
          <div className='input-date-content'>
            <label htmlFor='edit-resource-management-date'>Data:</label><br />
            <input type="date" id='resource-management-date' ref={date} />
          </div>
          <div className='buttons-content'>
            {isLoading
              ? (
                <LoadContent
                  isLoading={isLoading}
                  style={
                    {
                      button: {
                        fontSize: 25,
                      },
                      container: {
                        width: 25,
                      }
                    }
                  }
                />
              )
              : (
                <>
                  <button className='cancel-button' type='button' onClick={() => {
                    huddleClickClose(false);
                  }}>Cancelar</button>
                  <button className='save-button' type='submit'>Salvar</button>
                </>
              )}
          </div>
        </div>
      </form>
    </div>
  )
}
