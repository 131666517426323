/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react';
import { FiEdit2 } from 'react-icons/fi';
import { MdDelete, MdDescription } from 'react-icons/md';
import './style.scss';
import { type ElementarySchoolDecentWorkType } from '../../class/types/Upload_ElementarySchoolDecenteWork';
import { DisplayFileInfoTree } from '../DisplayFile';
import { FaEye } from 'react-icons/fa';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import RuleVerification from '../rule-verification';
import UserAPI from '../../class/User/UserAPI';
import { type UserClientProtocol } from '../../class/types/User/UserAPI-protocol';
import lscache from 'lscache';
import uploadElementarySchoolWeeklyPlanApi from '../../class/UploadElementarySchoolSchoolWeeklytWork/upload-weekly-plan-api';
import uploadKindergartenSchoolWeeklyPlanApi from '../../class/UploadKindergartenSchoolWeeklyPlan/upload-weekly-plan-api';
import uploadWorkshopWeeklyPlanApi from '../../class/UploadWorkshopWeeklytWork/upload-weekly-plan-api';
import LoadContent from '../Load-content';
import { HiCheck } from 'react-icons/hi';
import { IoMdDownload } from 'react-icons/io';

interface ContentTableWeeklyPlanType {
  index: number
  elementarySchoolWeeklyPlan: ElementarySchoolDecentWorkType
  elementarySchoolWeeklyPlans: ElementarySchoolDecentWorkType[]
  setElementarySchoolWeeklyPlan: React.Dispatch<React.SetStateAction<any[]>>
  setDisplayDescribeElementarySchoolWeeklyPlan: React.Dispatch<React.SetStateAction<boolean>>
  setDisplayEditElement: React.Dispatch<React.SetStateAction<boolean>>
  setElementId: React.Dispatch<React.SetStateAction<string>>
  type: 'elementary-school' | 'kindergartenSchool' | 'workshop'
  setElement: React.Dispatch<React.SetStateAction<ElementarySchoolDecentWorkType>>
  setDisplayWeeklyPlan: React.Dispatch<React.SetStateAction<boolean>>
  setDisplayReadChecking: React.Dispatch<React.SetStateAction<boolean>>
}
export default function ContentTableElementaryWeeklyPlan({
  setDisplayDescribeElementarySchoolWeeklyPlan,
  setElementarySchoolWeeklyPlan,
  setDisplayReadChecking,
  setDisplayEditElement,
  setDisplayWeeklyPlan,
  setElementId,
  setElement,
  elementarySchoolWeeklyPlan,
  elementarySchoolWeeklyPlans,
  index,
  type
}: ContentTableWeeklyPlanType): React.ReactElement {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [displayAnimation, setDisplayAnimation] = useState(false);
  const huddleDelete = () => {
    const user = lscache.get('user') as UserClientProtocol;

    if (!user || !user.id) {
      return;
    }

    setIsLoading(true);
    if (type === 'kindergartenSchool') {
      uploadKindergartenSchoolWeeklyPlanApi.delete(String(elementarySchoolWeeklyPlan.id), elementarySchoolWeeklyPlan.location.fileName).then(response => {
        if (!response) {
          return;
        }
        const indexElement = elementarySchoolWeeklyPlans.findIndex((value) => response.id === value.id);

        if (typeof indexElement !== 'number') {
          setIsLoading(false);
          return;
        }
        elementarySchoolWeeklyPlans.splice(indexElement, 1);

        setElementarySchoolWeeklyPlan([...elementarySchoolWeeklyPlans]);
        setIsLoading(false);
      }).catch((e) => {
        //
      });
      return;
    }
    if (type === 'workshop') {
      uploadWorkshopWeeklyPlanApi.delete(String(elementarySchoolWeeklyPlan.id), elementarySchoolWeeklyPlan.location.fileName).then(response => {
        if (!response) {
          return;
        }
        const indexElement = elementarySchoolWeeklyPlans.findIndex((value) => response.id === value.id);

        if (typeof indexElement !== 'number') {
          setIsLoading(false);
          return;
        }
        elementarySchoolWeeklyPlans.splice(indexElement, 1);

        setElementarySchoolWeeklyPlan([...elementarySchoolWeeklyPlans]);
        setIsLoading(false);
      }).catch(e => {
        //
        setIsLoading(false);
      });
      return;
    }
    uploadElementarySchoolWeeklyPlanApi.delete(String(elementarySchoolWeeklyPlan.id), elementarySchoolWeeklyPlan.location.fileName).then(response => {
      if (!response) {
        return;
      }
      const indexElement = elementarySchoolWeeklyPlans.findIndex((value) => response.id === value.id);

      if (typeof indexElement !== 'number') {
        setIsLoading(false);
        return;
      }
      elementarySchoolWeeklyPlans.splice(indexElement, 1);

      setElementarySchoolWeeklyPlan([...elementarySchoolWeeklyPlans]);
      setIsLoading(false);
    }).catch((e) => {
      //
    });
  };

  const huddleEdit = () => {
    setElementId(String(elementarySchoolWeeklyPlan.id));
    setDisplayEditElement(true);
  };

  const hundleDisplayReadChecking = () => {
    setElementId(String(elementarySchoolWeeklyPlan.id));
    setDisplayReadChecking(true);
  }

  const huddleDescribe = () => {
    setElementId(String(elementarySchoolWeeklyPlan.id));

    setDisplayDescribeElementarySchoolWeeklyPlan(true);
  };

  const formatDate = (date: string) => {
    const newDate = new Date(date);

    return newDate.toLocaleDateString('pt-BR', {
      timeZone: 'UTC',
      dateStyle: 'short'
    });
  };

  const huddleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLElement;

    if (target.tagName === 'svg' || target.tagName === 'path') {
      return;
    }

    setElement(elementarySchoolWeeklyPlan);
    setDisplayWeeklyPlan(true);
  };

  const formatName = (_fileName: string | undefined): string => {
    if (!_fileName) {
      return '';
    }

    if (_fileName.length > 15) {
      return _fileName.slice(0, 30).concat('...');
    }

    return _fileName;
  }

  useEffect(() => {
    setDisplayAnimation(false);
    setTimeout(() => {
      setDisplayAnimation(true);
    }, 300);
  }, [elementarySchoolWeeklyPlan]);

  if (!elementarySchoolWeeklyPlan) {
    return <></>
  }

  return (
    <div className={`content-table-element-elementary-school-weekly-plan display-animation-${displayAnimation}`} onClick={huddleClick}>
      <ul className='content-table-ul'>
        <li className='content-table-ul-li'>
          <h2>{index + 1}</h2>
        </li>
        <li className='content-table-ul-li'>
          <p>
            <DisplayFileInfoTree
              type={elementarySchoolWeeklyPlan.type}
            />
          </p>
        </li>
        <li className='content-table-ul-li'>
          <h2>
            {formatName(elementarySchoolWeeklyPlan.issuer?.userName)}
          </h2>
        </li>
        <li className='content-table-ul-li'>
          <h2>
            {elementarySchoolWeeklyPlan.classRoom}
          </h2>
        </li>
        <li className='content-table-ul-li'>
          <h2>
            {formatDate(elementarySchoolWeeklyPlan.date)}
          </h2>
        </li>
        <li className='content-table-ul-li status-icon-element'>
          {elementarySchoolWeeklyPlan.checked
            ? (
              <BsFillCheckCircleFill className='icon-element' style={{ color: '#118951' }} size={21} />
            )
            : ''
          }
        </li>
        <li>
          <a href={elementarySchoolWeeklyPlan.location ? elementarySchoolWeeklyPlan.location.url : ''} target='_blank'>
            <IoMdDownload size='20' style={{ color: '#118951' }} />
          </a>
        </li>
        <RuleVerification rule={['coordenador']}>
          <li className='content-table-ul-li'>
            <MdDescription size='20' style={{ color: '#118951' }} onClick={huddleDescribe} />
          </li>
        </RuleVerification>
        <RuleVerification rule={['coordenador']}>
          <li className='content-table-ul-li'>
            <HiCheck size='20' onClickCapture={hundleDisplayReadChecking} className='icon-hicheck-element' />
          </li>
        </RuleVerification>
        <li className='content-table-ul-li' onClick={huddleEdit}>
          <FiEdit2 size='20' style={{ color: '#118951' }} />
        </li>
        {isLoading
          ? (
            <li>
              <LoadContent
                isLoading={isLoading}
                style={{
                  button: {
                    fontSize: 20,
                    color: '#ff0000'
                  },
                  container: {
                    width: 20,
                  }
                }}
              />
            </li>
          )
          : (
            <li className='header-table-ul-li' onClick={huddleDelete}>
              <MdDelete size='20' style={{ color: '#ff0000' }} />
            </li>

          )}
      </ul>
    </div>
  );
}
