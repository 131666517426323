/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useRef, useState } from 'react';
import LoadContent from '../../../components/Load-content';
import { IoCloseOutline } from 'react-icons/io5';
import './styles.scss';
import SecretaryResourceCostumer from '../../../class/secretary-resource/secretary-resource-costumer';
import { useParams } from 'react-router-dom';
import { type SecretaryResourceType } from '../../../class/types/secretary-resource';
interface FormAddSecretaryResourceProps {
  display: boolean
  setDisplay: React.Dispatch<React.SetStateAction<boolean>>
  elements: SecretaryResourceType[]
  setElements: React.Dispatch<React.SetStateAction<SecretaryResourceType[]>>
}

// eslint-disable-next-line spaced-comment
export default function FormAddSecretaryResource({ display, setDisplay, elements, setElements }: FormAddSecretaryResourceProps) {
  const [isLoading, setIsLoading] = useState(false);
  const {id} = useParams();
  const action = useRef<HTMLInputElement | null>(null);
  const resourceSource = useRef<HTMLInputElement | null>(null);
  const initialDate = useRef<HTMLInputElement | null>(null);

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!id) {
      setIsLoading(false);
      return;
    }

    const form = e.target as HTMLFormElement;

    const inputs = form.querySelectorAll('input');
     const secretaryResourceManagement = new SecretaryResourceCostumer(inputs, id);

    setIsLoading(true);
    secretaryResourceManagement.post().then(response => {
      if (!response) {
        setIsLoading(false);
        return
      }
      setElements([response, ...elements]);

      setIsLoading(false);
      setDisplay(false);
      resetInformation();
    }).catch(e => {
      //
    });
  }

  const resetInformation = () => {
    if (!action.current || !resourceSource.current || !initialDate.current) {
      return;
    }

    action.current.value = '';
    resourceSource.current.value = '';
    initialDate.current.value = '';
  }

  const huddleClickClose = (bol: boolean) => {
    setDisplay(bol);
  }

  return (
    <div className={`form-add-secretary-resource display-${display}`}>
      <form method='POST' className='form-add-element' id='form-add-element' onSubmit={e => {
        huddleSubmit(e);
      }}>
        <div className='header-form-add-element-content'>
          <h1>
            Adicionar recurso
          </h1>
          <IoCloseOutline onClick={() => {
            huddleClickClose(false);
          }} size='25' />
        </div>
        <div className='body-form-add-element-content'>
          <div className='input-content'>
            <label htmlFor='secretary-resource-action'>Ação:</label>
            <input type='text' id='secretary-resource-action' placeholder='Ação' ref={action} />
          </div>
          <div className='input-content'>
            <label htmlFor='secretary-resource-source'>Fonte de recurso:</label>
            <input type='text' id='secretary-resource-source' placeholder='Digite a fonte de recurso' ref={resourceSource} />
          </div>
          <div className='input-date-content'>
            <label htmlFor='secretary-resource-initial-date'>Data de inicio:</label><br />
            <input type="date" id='secretary-resource-initial-date' ref={initialDate} />
          </div>
          <div className='buttons-content'>
            {isLoading
              ? (
                <LoadContent
                  isLoading={isLoading}
                  style={
                    {
                      button: {
                        fontSize: 25,
                      },
                      container: {
                        width: 25,
                      }
                    }
                  }
                />
              )
              : (
                <>
                  <button className='cancel-button' type='button' onClick={() => {
                    huddleClickClose(false);
                  }}>Cancelar</button>
                  <button className='save-button' type='submit'>Salvar</button>
                </>
              )}
          </div>
        </div>
      </form>
    </div>
  )
}
